import  { useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { ListItem  } from '@material-ui/core';
import { List } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import SendIcon from '@material-ui/icons/Send';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Typography } from '@material-ui/core';


function Home() {

  const classes = useStyles()
  const {t} = useTranslation();

  const [checked, setChecked] = useState('');
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return(
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    style={{ minHeight: '100vh' }}
    >
      <Card className={classes.card}>
      <CardHeader avatar={<InfoIcon align={'center'}/>} title={t('title')} titleTypographyProps={{variant:'h6'}} className={classes.cardheader}/>
      <CardContent>
      <Typography>{t('listtitle')}</Typography>
      <List dense={true}>
                <ListItem> 
                  <ListItemAvatar>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemAvatar> {t('list.0')}
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemAvatar> {t('list.1')}
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemAvatar> {t('list.2')}
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemAvatar> {t('list.3')}
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemAvatar> {t('list.4')}
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemAvatar> {t('list.5')}
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FiberManualRecordIcon fontSize='small'/>
                  </ListItemAvatar> {t('list.6')}
                </ListItem>
              </List>
             <h6>{t('faqtitle')}</h6>
              <p>{t('faqtext')}</p>
              <p><Checkbox checked={checked} onChange={handleChange} color={"black"}/> {t('termstext')} <Link to="/terms">{t("tac")}</Link>.</p>
              <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon/>}
                  disabled={!checked}
                  component={Link}
                  to={'/analysis'}
                > {t('next')}
              </Button>
      </CardContent>
      </Card>
    </Grid>
  );
}

const useStyles = makeStyles({
  logo: {
    maxWidth: 120,
    marginLeft: 100
  },
  card: {
    maxWidth: 600,
    borderColor: "grey",
    alignItems: "center"
  },
  cardheader: {
    alignItems: 'center',
    fontWeight: 'bold'
  },
  header:{
    fontSize: 14,
    fontWeight: 'bold'
  },
  link: {
    color: 'black',
  },
  nexbutton: {
    color: 'default',
    textDecoration: 'none'
  },
  formControl:{
      // margin: theme.spacing(1),
      maxWidth: 80,
      marginLeft: 10 
  },
  language:{
    fontSize: "small",
    marginLeft: 70
  }
});

export default Home;
