import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';
import { useState } from 'react';
import useGlobalQuestionnaireHistory from './globalStates/globalquestionnaire';    
import { useTranslation } from "react-i18next";

function Symptoms( {symptomList , diff_list} ){

    const [questionnaireHistory, setQuestionnaireHistory] = useGlobalQuestionnaireHistory();
    const [selected, setSelected] = useState()

    const selectionChangeHandler = (event) => {
        setSelected(event.target.value)
        setQuestionnaireHistory(questionnaireHistory => [...questionnaireHistory, {'value': event.target.value, 'diff_id': parseInt(event.target.name)}])
    }

    console.log(selected)
    console.log(questionnaireHistory)

    const {t} = useTranslation();

    return symptomList.map((symptom , index) => (
    <p>
    <FormControl component="fieldset" style={{marginBottom: 20, width: "100%"}}>
        <FormLabel component="legend">
                <Typography style={{wordWrap: "break-word"}} >{symptom}
                </Typography>
            </FormLabel>
            <RadioGroup row value={selected} onChange= {selectionChangeHandler}  aria-label={index} name={diff_list[index].toString()}>
                <FormControlLabel value="yes" control={<Radio />} label={t('yes')} />
                <FormControlLabel value="no" control={<Radio />} label={t('no')} />
                <FormControlLabel value="Not sure" control={<Radio />} label={t('notsure')} />
            </RadioGroup>
    </FormControl>
    </p>
    ))
}

export default Symptoms;
