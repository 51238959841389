import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Link } from "react-router-dom";
import ListAltIcon from "@material-ui/icons/ListAlt";
import * as questioncards from "./qcard";
import * as generate from "./generate_questions";
import useGlobalDisease from "./globalStates/disease_var";
import Symptoms from "./symptoms";
import { Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { useTranslation } from "react-i18next";
import useGlobalLang from "./globalStates/globalLanguageVar";

function Questionnaire() {
  const classes = useStyles();
  const [globalDisease, setGlobalDisease] = useGlobalDisease();
  const [globalLang, setGlobalLang] = useGlobalLang();

  const result = globalDisease.prediction;
  // Load Json
  const disease_classes_json = require("../questions/disease_classes.json");
  const disease_diff_json = require("../questions/disease_diff.json");
  const differential_diagnosis_json = require("../questions/differential_diagnosis.json");
  const diff_sym_json = require("../questions/diff_sym.json");
  const symptoms_json = require("../questions/symptoms.json")
  const symptoms_jsonDE = require("../questions/symptomsDE.json")
  const symptoms_jsonBR = require("../questions/symptomsBR.json")


  const questions = generate.generateQuestionnaire(
    result,
    disease_classes_json,
    disease_diff_json,
    differential_diagnosis_json,
    diff_sym_json,
    symptoms_json
  )

  const questionsDE = generate.generateQuestionnaire(
    result,
    disease_classes_json,
    disease_diff_json,
    differential_diagnosis_json,
    diff_sym_json,
    symptoms_jsonDE
  )

  const questionsBR = generate.generateQuestionnaire(
    result,
    disease_classes_json,
    disease_diff_json,
    differential_diagnosis_json,
    diff_sym_json,
    symptoms_jsonBR
  )


  const {t} = useTranslation();

  console.log('this is from questions: ',questions);

  const number_of_questions = 10

  return (
      <Card className={classes.card}>
        <CardHeader avatar={<ListAltIcon />} title={t('questionnaireheader')} titleTypographyProps={{variant:'h6' } }/>
        <CardContent>
          <p>
            {t('questionnairetext')}
          </p>
          <questioncards.Qcard disease_result={globalDisease.prediction} />
          <Typography style={{marginTop: 20, marginBottom:20}}> {t('questionsymptoms')}
                </Typography>
          {globalLang === 'en' ? <Symptoms symptomList= {questions[2].slice(0,number_of_questions)} diff_list={questions[3].slice(0,number_of_questions)}/> 
          : globalLang === 'de' ? <Symptoms symptomList= {questionsDE[2].slice(0,number_of_questions)} diff_list={questionsDE[3].slice(0,number_of_questions)}/>
          : <Symptoms symptomList= {questionsBR[2].slice(0,number_of_questions)} diff_list={questionsBR[3].slice(0,number_of_questions)}/>
          }
          
            <Button
              variant="contained"
              color="primary"
              endIcon={<SendIcon/>}
              component={Link}
              // disabled={imagesUpload}
              to={{pathname:'/results'}}
              className={classes.button}
              > {t('next')}
            </Button>
        </CardContent>
      </Card>
  );
}

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
    borderColor: "grey",
    alignItems: "center",
    align: "center",
  },
  cardheader: {
    alignItems: "center",
    fontWeight: "bold",
  },
  header: {
    fontSize: 14,
    fontWeight: "bold",
  },
  link: {
    color: "black",
  },
  button: {
    alignItems: "right",
    align: "right",
    justifyContent: "right",
  },
});

export default Questionnaire;
