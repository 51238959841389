/**
 * useGlobalCounter.js
 */

import createGlobalState from "react-create-global-state";

// create the global for your hook
const initialState = [];

const [useGlobalFiles, Provider] = createGlobalState(initialState);

// export the provider to link in the application
export const GlobalFilesProvider = Provider;

// export the hook
export default useGlobalFiles;

