import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from "@material-ui/core";
import useGlobalDisease from "./globalStates/disease_var";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Grid, Box } from "@material-ui/core";
import ProgressProvider from "./progressProvider";
import {  useTranslation } from "react-i18next";


function round(value, precision=1) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
function EtcResult() {
  const [globalDisease, setGlobalDisease] = useGlobalDisease();
  const confidence = round(globalDisease.confidence * 100, 1); 
  const {t} = useTranslation();

  return (
      <Box maxWidth={600}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Card style={{ border: "none", boxShadow: "none" }}>
                <CardHeader avatar={<CheckCircleIcon />} title={t('resultstitle')} titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                  <Typography>
                     {t('resultsconfidenceothers')}  <Box component="span" fontWeight='fontWeightBold'>{confidence}%</Box>. {t('resultspre')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card style={{ border: "none", boxShadow: "none", marginTop: 10, marginRight: 10 }}>
                <CardContent>
                  <ProgressProvider valueStart={0} valueEnd={confidence}>
                    {(confidence) => (
                      <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar value={confidence} text={`${confidence}%`} />
                      </div>
                    )}
                  </ProgressProvider>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Card>
            <CardContent>
              <Typography>
                {t('thankyou')}{" "}
                <a href="https://www.nail-vision.de" target="_blank" rel="noreferrer">website</a>.
              </Typography>
              <Typography style={{marginTop: 30}}>
                    {t('resultsdisclaimer')}
                </Typography>
            </CardContent>
          </Card>
        </div>
      </Box>
  );
}

export default EtcResult;