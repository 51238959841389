import { Grid, makeStyles } from "@material-ui/core";
import { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import useGlobalDisease from "./globalStates/disease_var";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useGlobalFiles from "./globalStates/files";
import heic2any from "heic2any";
import { } from "heic-convert";
import { useTranslation } from "react-i18next";

function getCanvasBlob(canvas) {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(
      function (blob) {
        resolve(blob);
      },
      "image/png",
      1,
    );
  });
}


export default function DropZone() {
  const [files, setFiles] = useGlobalFiles([]);
  const [globalDisease, setGlobalDisease] = useGlobalDisease();
  const [analyzing, setAnalyzing] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [crop, setCrop] = useState({ unit: "%", width: 50, height: 50, x: 25, y: 25 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const { t } = useTranslation();

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles[0].type === "image/heic") {
      acceptedFiles[0] = await heic2any({ blob: acceptedFiles[0] });
    }
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: "image/*", multiple: false });


  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const Cropper = files.map((file) => (
    <div key={file.name}>
      <div>
        <ReactCrop
          src={file.preview}
          onImageLoaded={onLoad}
          crop={crop}
          onComplete={(c) => setCompletedCrop(c)}
          onChange={(newCrop) => setCrop(newCrop)}
        />
        <div>
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
              display: "none",
            }}
          />
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  const url = "https://k0ns.de:12345";
  const onCropAndUpload = async (e) => {
    const blob = await getCanvasBlob(previewCanvasRef.current);
    const response = await fetch(url, {
      method: "POST",
      body: blob,
    });
    const data = await response.json();
    console.log(data);
    if (data.confidence > 0.999) data.confidence = 0.999; // 100% looks fishy..
    setGlobalDisease(data);
    return data;
  };
  return (
    <>
      {files.length > 0 && (
        <Grid container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
            <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "10px", marginTop: "-20px", float: "left" }}
            className={classes.buttonLeft}
            disabled={files.length === 0 || analyzing}
            onClick={(e) => {
              setAnalyzing(true);
              onCropAndUpload(e)
                .then((diseaseData) =>
                  history.push(diseaseData.prediction === "normal" ? "/normalresult"
                    : diseaseData.prediction === "etc" ? "/etcresult"
                      : "/questionnaire"),
                )
                .then((_) => setAnalyzing(false));
            }}
          >
            {analyzing && (
              <>
                <CircularProgress size={14} color={"secondary"} style={{ marginRight: "5px" }} />
                {<>{t('analyseloading')}</>}
              </>
            )}
            {!analyzing && <>{t('analysebutton')}</>}
          </Button>
          </Grid>
          <Grid item xs>
          <Button 
            disabled={files.length === 0 || analyzing}
            variant="contained"
            color="primary"
            className={classes.buttonRight}
            style={{ marginBottom: "10px", marginTop: "-20px", position: "relative", float: "right" }}
            onClick={(e) => {
              setFiles([])
            }}>
            {t('newimagebutton')}
          </Button>
          </Grid>
        </Grid>)}
      <div>{Cropper}</div>
      {files.length === 0 && (
        <>
          <div {...getRootProps()} className={classes.dropzone}>
            <input {...getInputProps()} />
            <p>{t('dropbox')}</p>
          </div>
        </>
      )}
    </>
  );
}

const useStyles = makeStyles({
  dropzone: {
    flex: 1,
    display: "flex",
    maxWidth: 600,
    flexDirection: "column",
    alignItems: "center",
    padding: "100px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    cursor: "pointer",
  },
  buttonLeft: {
    margin: "5px",
    marginLeft: "0px"
  },
  buttonRight: {
    margin: "5px",
    marginRight: "0px"
  }
});
