import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import { IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import i18n from "i18next";
import LanguageIcon from "@material-ui/icons/Language";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory, useLocation } from "react-router-dom";
import useGlobalLang from "./globalStates/globalLanguageVar";

export default function AppBarComp() {
  const classes = useStyles();
  const [globalLang, setGlobalLang] = useGlobalLang();
  i18n.changeLanguage(globalLang);
  const handleLangChange = (event) => {
    i18n.changeLanguage(event.target.value);
    setGlobalLang(event.target.value);
  };
  const history = useHistory();
  const location = useLocation();
  console.log(location);
  let backAction = history.goBack;
  if (location.pathname === "/analysis") backAction = () => history.push("/");
  if (location.pathname === "/") backAction = () => {};
  if (location.pathname === "/terms") backAction = () => history.push("/");
  if (location.pathname.includes("result")) backAction = () => history.push("/analysis");
  if (location.pathname === "/questionnaire") backAction = () => history.push("/analysis");

  return (
    <AppBar position="sticky" color="primary" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          onClick={(e) => {
            backAction();
          }}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBackIosIcon style={{ paddingLeft: "5px" }} />
        </IconButton>
        <img
          src="https://static.wixstatic.com/media/4fd1b1_97a0c2d910694aacbb4aed52faa58183~mv2.png/v1/fill/w_238,h_110,al_c,usm_0.66_1.00_0.01/nailvision_logo_schwarz.png"
          alt="logo"
          className={classes.logo}
        />
        <FormHelperText>
          <LanguageIcon className={classes.language} />
        </FormHelperText>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={globalLang}
            onChange={handleLangChange}
            label="Lang"
          >
            <MenuItem value={"en"}>EN</MenuItem>
            <MenuItem value={"de"}>DE</MenuItem>
            <MenuItem value={"br"}>BR</MenuItem>
          </Select>
        </FormControl>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles({
  logo: {
    maxWidth: 120,
    margin: "auto",
  },
  appbar: {
    display: "flex",
    alignItems: "center",
    align: "center",
  },
  toolbar: {
    width: "100%",
    maxWidth: 600,
  },
  link: {
    color: "black",
  },
  formControl: {
    // margin: theme.spacing(1),
    maxWidth: 70,
    marginLeft: 10,
    float: "right",
  },
  language: {
    fontSize: "large",
    float: "right",
    marginBottom: 5,
  },
});
