import React from 'react'


// Write individual functions

export function getDiseaseIDfromName(result, disease_json) {
    // iterate over each element in the array
        for (var i = 0; i < disease_json.length; i++){
            // look for the entry with a matching `code` value
            if (disease_json[i].diseaseName === result){
                return disease_json[i].disease_id
            }
        }
}

export function getDifferentialIDs(disease_id, disease_diff_json){
    let diff_ids = []
    // iterate over each element in the array
        for (var i = 0; i < disease_diff_json.length; i++){
            // look for the entry with a matching `code` value
            if (disease_diff_json[i].disease_id === disease_id){
                diff_ids.push(disease_diff_json[i].diff_id)
            }
        }
        return diff_ids
}

export function getDifferentialNames(diff_ids, differential_diagnosis_json){
    let differential_names = []
    // iterate over each element in the array
    for (var i = 0; i < diff_ids.length; i++){
        for (var j = 0; j < differential_diagnosis_json.length; j++){
            // look for the entry with a matching `code` value
            if (differential_diagnosis_json[j].diff_id === diff_ids[i]){
                differential_names.push(differential_diagnosis_json[j].diffName)
            }
        }
    }
    return differential_names
}

export function getSymptomIDs(diff_ids, diff_sym_json){
    let sym_ids = []
    let diff_list = []
    // iterate over each element in the array
    for (var i = 0; i < diff_ids.length; i++){
        for (var j = 0; j < diff_sym_json.length; j++){
            // look for the entry with a matching `code` value
            if (diff_sym_json[j].diff_id === diff_ids[i]){
                sym_ids.push(diff_sym_json[j].sym_id)
                
            }
        }
    }
    return sym_ids
}

export function getDiffList(diff_ids, diff_sym_json){
    let diff_list = []
    // iterate over each element in the array
    for (var i = 0; i < diff_ids.length; i++){
        for (var j = 0; j < diff_sym_json.length; j++){
            // look for the entry with a matching `code` value
            if (diff_sym_json[j].diff_id === diff_ids[i]){
                diff_list.push(diff_sym_json[j].diff_id)
                
            }
        }
    }
    return diff_list
}

export function getSymptomQuestions(sym_ids, symptoms_json){
    let symptomQuestions = []
    // iterate over each element in the array
    for (var i = 0; i < sym_ids.length; i++){
        for (var j = 0; j < symptoms_json.length; j++){
            // look for the entry with a matching `code` value
            if (symptoms_json[j].sym_id === sym_ids[i]){
                symptomQuestions.push(symptoms_json[j].symName)
            }
        }
    }
    return symptomQuestions
}

// Wrapper function
export function generateQuestionnaire(result, disease_classes_json, disease_diff_json,
    differential_diagnosis_json, diff_sym_json, symptoms_json
    ){
       var disease_id = getDiseaseIDfromName(result, disease_classes_json)
       var diff_ids = getDifferentialIDs(disease_id, disease_diff_json)
       var differential_names = getDifferentialNames(diff_ids, differential_diagnosis_json)
       var sym_ids = getSymptomIDs(diff_ids, diff_sym_json)
       var diff_list = getDiffList(diff_ids, diff_sym_json)
       var symptomQuestions = getSymptomQuestions(sym_ids, symptoms_json)

       return [result, differential_names, symptomQuestions, diff_list]
}

// Load Json
const disease_classes_json = require('../questions/disease_classes.json')
const disease_diff_json = require('../questions/disease_diff.json')
const differential_diagnosis_json = require('../questions/differential_diagnosis.json')
const diff_sym_json = require('../questions/diff_sym.json')
const symptoms_json = require('../questions/symptoms.json')

// Test result
const result = 'onychomycosis'
const questions = generateQuestionnaire(result, disease_classes_json, disease_diff_json,
differential_diagnosis_json, diff_sym_json, symptoms_json)

console.log(questions)