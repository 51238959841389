import React from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';
import { useState } from 'react';
import useGlobalFamilyHistory from './globalStates/familyhistory'
import { useTranslation } from 'react-i18next';
export function Qcard( { disease_result } ) {

    const {t} = useTranslation();
    const [selected, setSelected] = useState('yes')

    const [familyHistory, setFamilyHistory] = useGlobalFamilyHistory();

    const selectionChangeHandler = (event) => {
        setSelected(event.target.value)
        setFamilyHistory(event.target.value)

    }
    console.log(selected)
    console.log(familyHistory)
    return (
    <FormControl component="fieldset">
        <FormLabel component={ disease_result }>
                <Typography> {t('questionhistory')} { disease_result }?
                </Typography>
            </FormLabel>
            <RadioGroup row value={selected} onChange= {selectionChangeHandler} aria-label={disease_result} name="medical-history">
                <FormControlLabel value="yes" control={<Radio />} label={t('yes')} />
                <FormControlLabel value="no" control={<Radio />} label={t('no')} />
                <FormControlLabel value="Not sure" control={<Radio />} label={t('notsure')} />
            </RadioGroup>
    </FormControl>
    )
}

export function SymptomQuestions({questions}){
    
}