import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Backup, PhotoLibrary, Crop } from '@material-ui/icons';
import DropZone from './dropzone';
import { CardHeader, CardContent, Card} from '@material-ui/core';
import useGlobalDisease from './globalStates/disease_var'
import useGlobalFiles from './globalStates/files';
import {  useTranslation } from "react-i18next";
import { Typography } from '@material-ui/core';
function Analysis() {


  const classes = useStyles()
  const [globalDisease, setGlobalDisease] = useGlobalDisease();
  const [files, setFiles] = useGlobalFiles();


  const [isNormal, setIsNormal] = useState(null)

  useEffect(() => {
    if (globalDisease.prediction === "normal") {
      setIsNormal(true);
    }
  }, [globalDisease.prediction]);

  const [isEtc, setIsEtc] = useState(null)

  useEffect(() => {
    if (globalDisease.prediction === "etc") {
      setIsEtc(true);
    }
  }, [globalDisease.prediction]);

  const { t } = useTranslation();


  console.log(files)
  return (
    <>
      <Card className={classes.card}>
        <CardHeader avatar={<PhotoLibrary />} title={t('analysisheader')} titleTypographyProps={{ variant: "h6" }} />
        <CardContent>
          <h6></h6>
          <img src="/images/Nailvision_mobile_sketches/finger_frontal.png" width={"300px"} />
          <p>
            {t("analysistext")}
          </p>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader
          avatar={files.length === 0 ? <Backup /> : <Crop />}
          title={files.length === 0 ? <Typography>{t('upload')}</Typography> : <Typography>{t('crop')}</Typography>}
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <DropZone></DropZone>
        </CardContent>
      </Card>
    </>
  );
}

const useStyles = makeStyles({
  logo: {
    maxWidth: 120,
    marginLeft: 100
  },
  card: {
    maxWidth: 600,
    alignItems: "center",
    border: "none",
    boxShadow: "none"
  },
  cardheader: {
    alignItems: 'center',
    fontWeight: 'bold'
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  link: {
    color: 'black',
  },
  button: {
    alignItems: 'right',
    align: 'right',
    justifyContent: 'right'
  },
  formControl: {
    // margin: theme.spacing(1),
    maxWidth: 80,
    marginLeft: 10
  },
  language: {
    fontSize: "small",
    marginLeft: 70
  }
});

export default Analysis;
