/**
 * useGlobalCounter.js
 */

 import createGlobalState from "react-create-global-state";

 // create the global for your hook
 const initialState = [];
 
 const [useGlobalFamilyHistory, Provider] = createGlobalState(initialState);
 
 // export the provider to link in the application
 export const GlobalFamilyProvider = Provider;
 
 // export the hook
 export default useGlobalFamilyHistory;
 
 