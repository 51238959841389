import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {  useTranslation } from "react-i18next";




function Toc() {
  const { t } = useTranslation();
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Card className={classes.card}>
        <CardHeader title={t("tac")} titleTypographyProps={{ variant: 'h6' }} className={classes.cardheader} />
        <CardContent>
          <h6>{t('termsheaders.0')}</h6>
          <p>{t('termsintro')}
          </p>
          <h6>{t('termsheaders.1')}</h6>
          <p>{t('termsservices')}
          </p>
          <h6>{t('termsheaders.2')}</h6>
          <p>{t('termsusage')}
          </p>
          <h6>{t('termsheaders.3')}</h6>
          <p>{t('termsmedical')}
          </p>
          <h6>{t('termsheaders.4')}</h6>
          <p>{t('termsprivacy')}
          </p>
        </CardContent>
      </Card>
    </Grid>
  );
}

const useStyles = makeStyles({
  logo: {
    maxWidth: 120,
    marginLeft: 100
  },
  card: {
    maxWidth: 600,
    borderColor: "grey",
    alignItems: "center"
  },
  cardheader: {
    alignItems: 'center',
    fontWeight: 'bold'
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  link: {
    color: 'black',
  },
  nexbutton: {
    color: 'default',
    textDecoration: 'none'
  },
  formControl: {
    // margin: theme.spacing(1),
    maxWidth: 80,
    marginLeft: 10
  },
  language: {
    fontSize: "small",
    marginLeft: 70
  }
});

export default Toc;
