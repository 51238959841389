import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography } from "@material-ui/core";
import useGlobalDisease from "./globalStates/disease_var";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Grid, Box } from "@material-ui/core";
import ProgressProvider from "./progressProvider";
import useGlobalFamilyHistory from './globalStates/familyhistory'
import useGlobalQuestionnaireHistory from './globalStates/globalquestionnaire';
import * as generate from "./generate_questions";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { send } from 'emailjs-com';


function round(value, precision = 1) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
function Results() {

  const classes = useStyles()

  const [globalDisease, setGlobalDisease] = useGlobalDisease();
  const [familyHistory, setFamilyHistory] = useGlobalFamilyHistory();
  const [questionnaireHistory, setQuestionnaireHistory] = useGlobalQuestionnaireHistory();

  const result = globalDisease.prediction;

  // Load Json
  const disease_diff_json = require("../questions/disease_diff.json");
  const differential_diagnosis_json = require("../questions/differential_diagnosis.json");


  function getConfirmedDiagnoses(global_question_results_array, disease_diff_json) {
    let differential_diagnoses = []
    // iterate over each element in the array
    for (var i = 0; i < global_question_results_array.length; i++) {
      if (global_question_results_array[i].value === 'yes') {
        differential_diagnoses.push(global_question_results_array[i].diff_id)
      }
    }
    let unique_diff_ids = [...new Set(differential_diagnoses)]



    return generate.getDifferentialNames(unique_diff_ids, differential_diagnosis_json)
  }



  const confirmed_diagnoses = getConfirmedDiagnoses(questionnaireHistory, disease_diff_json)
  const confidence = round(globalDisease.confidence * 100, 1);

  const { t } = useTranslation();

  const [toSend, setToSend] = useState({
    from_name: '',
    message: '',
    reply_to: '',
  });

  const [wasSend, setWasSend] = useState()

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_fb354e9',
      'template_9c8zg5o',
      toSend,
      'user_lfTCWkdLo0MrbCH0S0tCB'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setWasSend(true)
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Box maxWidth={600}>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardHeader avatar={<CheckCircleIcon />}
                title={t('resultstitle')}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <Typography> {t('resultsconfidence')} <Box component="span" fontWeight='fontWeightBold'>{confidence}%</Box>. {t('middle')} <Box component="span" fontWeight='fontWeightBold'>{result}</Box>{"."}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card style={{ border: "none", boxShadow: "none", marginTop: 10, marginRight: 10 }}>
              <CardContent>
                <ProgressProvider valueStart={0} valueEnd={confidence}>
                  {(confidence) => <div style={{ width: 100, height: 100 }}>
                    <CircularProgressbar value={confidence} text={`${confidence}%`} />
                  </div>}
                </ProgressProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            {
              familyHistory === 'yes' ? <Typography>

              </Typography>
                : <Typography>
                  {t('resultshistorypos')} {result}.
                </Typography>
            }
            {confirmed_diagnoses.length > 0 ?
              <Typography style={{ marginTop: 0 }}>
                {t('symotomspre')}
                {confirmed_diagnoses.map(diagnosis => <ul style={{ marginTop: 10 }}><li>{diagnosis}</li></ul>)}
                {t('suggest')}
              </Typography>
              :
              <Typography style={{ marginTop: 10 }}>
                {t('nosymptoms')}
              </Typography>
            }
            <Typography style={{ marginTop: 30 }}>
              {t("")}
            </Typography>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography>
              {t('thankyou')}{' '}
              <a href="https://www.nail-vision.de" target="_blank" rel="noreferrer">website</a>.
            </Typography>
            <Grid>
              <form onSubmit={onSubmit} className={classes.submitfield}>
                <Typography variant="h6"><strong>{t('contacttitle')}</strong></Typography>
                <input
                  type='text'
                  name='from_name'
                  placeholder={t('contactname')}
                  value={toSend.from_name}
                  onChange={handleChange}
                />
                <input
                  type='text'
                  name='message'
                  placeholder={t('contactmessage')}
                  value={toSend.message}
                  onChange={handleChange}
                />
                <input
                  type='text'
                  name='reply_to'
                  placeholder={t('contactmail')}
                  value={toSend.reply_to}
                  onChange={handleChange}
                />
                <button type='submit'>
                  {t('submit')}
                </button>
                {wasSend ? <Typography style={{ marginTop: 5 }}><CheckCircleIcon fontSize={"small"} /> {t('thankyoumail')}</Typography> : <></>}
              </form>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Box>
  );
}

const useStyles = makeStyles({
  card: {
    maxWidth: 600,
    borderColor: "grey",
    alignItems: "center",
    align: "center",
  },
  cardheader: {
    alignItems: "center",
    fontWeight: "bold",
  },
  header: {
    fontSize: 14,
    fontWeight: "bold",
  },
  link: {
    color: "black",
  },
  button: {
    alignItems: "right",
    align: "right",
    justifyContent: "right",
  },
  submitfield: {
    marginTop: 30
  }
});

export default Results;