/**
 * useGlobalCounter.js
 */

 import createGlobalState from "react-create-global-state";

 // create the global for your hook
 const initialState = "en";
 
 const [useGlobalLang, Provider] = createGlobalState(initialState);
 
 // export the provider to link in the application
 export const GlobalLangProvider = Provider;
 
 // export the hook
 export default useGlobalLang;
 
 