import i18n from "i18next";
import { Trans, useTranslation, initReactI18next } from "react-i18next";
import translationEN from './components/translations/translationEN.json'
import translationDE from './components/translations/translationDE.json'
import translationBR from './components/translations/translationBR.json'


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(  {
    resources: {
        en: {translation: translationEN},
        de: {translation: translationDE},
        br: {translation: translationBR}
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {escapeValue: false}
    }); 



export default i18n

