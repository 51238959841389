import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/home'
import Analysis from './components/analysis'
import Questionnaire from './components/questionnaire'
import Results from './components/results'
import Toc from './components/toc'
import Grid from '@material-ui/core/Grid';
import history from './components/history'
import { GlobalDiseaseProvider } from './components/globalStates/disease_var'
import NormalResult from './components/normalResult'
import EtcResult from './components/etcresult'
import { GlobalFilesProvider } from './components/globalStates/files'
import { GlobalFamilyProvider } from './components/globalStates/familyhistory'
import { GlobalQuestionnaireProvider } from './components/globalStates/globalquestionnaire'
import { Suspense } from 'react'
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles';
import AppBarComp from './components/appbar'
import { GlobalLangProvider } from './components/globalStates/globalLanguageVar'
import ScrollToTop from "./components/scrollToTop";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e0e0e0',
    }
  }
});

function App({ t }) {

  console.log("Welcome to NailVision")
  return (
    <Suspense fallback="Loading...">
      <Router history={history} forceRefresh={true}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          style={{ minHeight: "100vh", minWidth: "100vw", maxWidth: "100vw" }}
        >
          <GlobalDiseaseProvider>
            <GlobalFilesProvider>
              <GlobalFamilyProvider>
                <GlobalQuestionnaireProvider>
                  <GlobalLangProvider>
                    <Router>
                      <Switch>
                        <ThemeProvider theme={theme}>
                          <AppBarComp />
                          <ScrollToTop />
                          <Route path="/" exact key={Math.random()}><Home /></Route>
                          <Route path="/analysis" key={Math.random()}><Analysis /></Route>
                          <Route path="/questionnaire"><Questionnaire /></Route>
                          <Route path="/results"><Results /></Route>
                          <Route path="/terms"><Toc /></Route>
                          <Route path="/normalresult"><NormalResult /></Route>
                          <Route path="/etcresult"><EtcResult /></Route>
                        </ThemeProvider>
                      </Switch>
                    </Router>
                  </GlobalLangProvider>
                </GlobalQuestionnaireProvider>
              </GlobalFamilyProvider>
            </GlobalFilesProvider>
          </GlobalDiseaseProvider>
        </Grid>
      </Router>
    </Suspense>
  );

}

export default App;
