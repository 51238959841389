/**
 * useGlobalCounter.js
 */

 import createGlobalState from "react-create-global-state";

 // create the global for your hook
 const initialState = [];
 
 const [useGlobalQuestionnaireHistory, Provider] = createGlobalState(initialState);
 
 // export the provider to link in the application
 export const GlobalQuestionnaireProvider = Provider;
 
 // export the hook
 export default useGlobalQuestionnaireHistory;
 
 